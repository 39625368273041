// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-gallery-collection-photo-template-wrapper-js": () => import("./../../../src/components/gallery/collection-photo-template-wrapper.js" /* webpackChunkName: "component---src-components-gallery-collection-photo-template-wrapper-js" */),
  "component---src-components-gallery-collection-template-wrapper-js": () => import("./../../../src/components/gallery/collection-template-wrapper.js" /* webpackChunkName: "component---src-components-gallery-collection-template-wrapper-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photos-js": () => import("./../../../src/pages/photos.js" /* webpackChunkName: "component---src-pages-photos-js" */)
}

